<template>
  <div class="dynamic-compose">
    <el-scrollbar ref="ElScrollbar">
      <div class="dynamic-compose-item" v-for="(item, index) in value" :key="item[idKey]">
        <slot
          :data="item"
          :index="index"
          :id="item[idKey]"
          :count="count"
          :handleAdd="handleAdd"
          :setAddable="setAddable"
          :handleDelete="handleDelete"
          :toogleAddable="toogleAddable"
          :deleteDisabled="deleteDisabled" />
      </div>
    </el-scrollbar>
    <slot name="add" :addDisabled="addDisabled" :reachMax="reachMax" :handleAdd="handleAdd">
      <el-button
        type="primary"
        icon="el-icon-plus"
        circle
        :disabled="addDisabled"
        @click="handleAdd"></el-button>
    </slot>
  </div>
</template>

<script>
  export default {
    provide() {
      return {
        DynamicCompose: this,
      };
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      min: {
        type: Number,
        default: 1,
        validator(val) {
          return val >= 0;
        },
      },
      max: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER,
        validator(val) {
          return val > 0;
        },
      },
      createItem: {
        type: Function,
        default: () => ({}),
      },
    },
    data() {
      return {
        idKey: `id_${String(Math.random()).replace(/\./g, '')}`,
        addable: true,
        uid: this.value.length,
      };
    },
    // created() {
    // this.initialize();
    // },
    watch: {
      value: {
        handler(newValue, oldValue) {
          console.log('newValue++++', newValue, oldValue);
          if (oldValue && newValue && oldValue.length !== newValue.length) {
            if (this.$refs.ElScrollbar) {
              console.log('this.$refs.ElScrollbar', this.$refs.ElScrollbar);
              this.$nextTick(this.$refs.ElScrollbar.update);
            }
          }
          this.initialize();
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      count() {
        return this.value.length;
      },
      reachMin() {
        return this.value.length === this.min;
      },
      reachMax() {
        return this.value.length === this.max;
      },
      addDisabled() {
        if (this.reachMax) {
          return true;
        }
        return !this.addable;
      },
      deleteDisabled() {
        return this.reachMin;
      },
    },
    methods: {
      initialize() {
        const { idKey, value, $set } = this;
        value.forEach(item => {
          if (!item[idKey]) {
            $set(item, idKey, this.updateUid());
          }
        });
      },
      updateUid() {
        return this.uid++;
        // return ++this.uid;
      },
      setAddable(flag) {
        this.addable = flag;
      },
      toogleAddable() {
        this.addable = !this.addable;
      },
      handleDelete(idValue) {
        if (this.deleteDisabled) {
          return;
        }
        const { idKey, value } = this;
        const len = value.length;
        const index = value.findIndex(item => item[idKey] === idValue);
        if (index >= 0 && index <= len - 1) {
          const newValue = [...value];
          const temp = {};
          newValue[index] = temp;
          this.$emit(
            'input',
            newValue.filter(item => item !== temp)
          );
        }
      },
      handleAdd() {
        if (this.addDisabled) {
          return;
        }
        const { idKey, createItem } = this;
        const newItem = createItem();
        newItem[idKey] = this.updateUid();
        this.$emit('input', [...this.value, newItem]);
      },
    },
  };
</script>

<style lang="scss">
  .dynamic-compose {
    .el-scrollbar {
      .el-scrollbar__wrap {
        max-height: 300px; // 最大高度
        overflow-x: hidden; // 隐藏横向滚动栏
        display: flex;
        flex-flow: column-reverse;
        align-items: baseline;
      }
    }
    &-item {
      width: calc(100% - 12px);
    }
  }
</style>
