<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="电站名称">
      <div v-if="operateType === 'single'">
        {{ currentRow.stationName }}
      </div>
      <el-scrollbar v-else-if="operateType === 'multiple'" :viewStyle="{ maxHeight: '200px' }">
        <div v-for="item in selectedRows" style="margin: 4px 0" :key="item.stationId">
          {{ item.stationName }}
        </div>
      </el-scrollbar>
    </ykc-form-item>
    <ykc-form-item label="配置方式" prop="configType">
      <ykc-radio :data="configTypeData" v-model="ruleForm.configType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item v-if="ruleForm.configType === '1'" label="选择电站" prop="configStationId">
      <ykc-dropdown
        v-model="ruleForm.configStationId"
        placeholder="请选择电站"
        :remote="true"
        :data="stationData"
        @change="handleStationChange"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item label="费率类别" prop="priceType">
      <ykc-radio :data="configCategoryData" v-model="ruleForm.priceType"></ykc-radio>
    </ykc-form-item>
    <peaksValleys v-if="ruleForm.priceType === '0'" ref="ykcPrice" :priceData="priceData" />
    <ykc-form-item v-else label="价格配置" prop="priceList">
      <RangePrice ref="RangePrice" v-model="ruleForm.priceList" :restConfig="restConfig" />
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { barrierPage, chargeStrategy } from '@/service/apis';

  import RangePrice from '@/components/dynamic-compose/range-price.vue';
  import peaksValleys from '@/components/dynamic-compose/peaks-valleys.vue';

  export default {
    props: ['currentRow', 'operateType', 'selectedRows'],
    components: { RangePrice, peaksValleys },
    data() {
      return {
        stationData: [],
        configTypeData: [
          {
            id: '0',
            name: '手动配置',
          },
          {
            id: '1',
            name: '引用其他站点',
          },
        ],
        configCategoryData: [
          {
            id: '0',
            name: '尖峰平谷',
          },
          {
            id: '1',
            name: '48费率',
          },
        ],
        priceData: {}, // 电价数据
        rules: {
          configType: [
            {
              required: true,
              message: '请选择配置方式',
              trigger: 'blur',
            },
          ],
          priceType: [
            {
              required: true,
              message: '请选择费率类别',
              trigger: 'blur',
            },
          ],
          configStationId: [
            {
              required: true,
              message: '请选择电站',
              trigger: 'blur',
            },
          ],
          priceList: [
            {
              required: true,
              type: 'array',
              message: '请添加价格设置',
              trigger: 'blur',
            },
          ],
        },
        ruleForm: {
          configType: '0',
          priceType: '0',
          configStationId: '',
          priceList: [],
        },
        restConfig: {
          charge: '',
          service: '',
        },
      };
    },
    watch: {
      'ruleForm.priceList': {
        deep: true,
        handler() {
          this.$refs.ruleForm && this.$refs.ruleForm.validateField('priceList');
        },
      },
    },
    created() {
      const setDefault = key => {
        this.ruleForm[key] = this.currentRow[key] || this.ruleForm[key];
      };

      if (this.operateType === 'single') {
        ['configType', 'priceType', 'configStationId'].map(setDefault);
        if (this.ruleForm.priceType === '0') {
          this.priceData = {
            feeList: this.currentRow.feeList,
            priceList: this.currentRow.priceList,
          };
        } else {
          this.changePriceList(this.currentRow.priceList);
        }
      } else {
        this.resetPriceData();
      }

      this.getStationData();
    },
    methods: {
      /**
       * 48费率回显
       */
      changePriceList(priceList) {
        if (Array.isArray(priceList) && priceList.length > 0) {
          this.ruleForm.priceList = priceList
            .filter(({ defaultFlag }) => String(defaultFlag) === '0')
            .map(({ priceStartPeriod, priceEndPeriod, powerRate, serviceRate }) => ({
              start: priceStartPeriod,
              end: priceEndPeriod,
              charge: powerRate,
              service: serviceRate,
              startData: [],
              endData: [],
            }));
          const restConfig = priceList.find(({ defaultFlag }) => String(defaultFlag) === '1');
          if (restConfig) {
            this.restConfig = {
              charge: restConfig.powerRate,
              service: restConfig.serviceRate,
            };
            console.log('restConfig', this.restConfig);
          }
        } else {
          this.resetPriceData();
        }
      },
      /**
       * 重置48费率数据
       */
      resetPriceData() {
        this.ruleForm.priceList = [
          {
            start: '',
            end: '',
            charge: '',
            service: '',
            startData: [],
            endData: [],
          },
        ];
        this.restConfig = {
          charge: '',
          service: '',
        };
      },
      /**
       * 获取电站数据
       */
      getStationData() {
        barrierPage.findStationList({}).then(res => {
          this.stationData = res.map(({ stationId, stationName }) => ({
            id: String(stationId),
            name: stationName,
          }));
        });
      },
      /**
       * 选择电站
       */
      handleStationChange(stationId) {
        if (!stationId) {
          this.resetPriceData();
          return;
        }
        if (
          this.operateType === 'single' &&
          String(stationId) === String(this.currentRow.stationId)
        ) {
          if (this.ruleForm.priceType === '0') {
            this.priceData = {
              feeList: this.currentRow.feeList,
              priceList: this.currentRow.priceList,
            };
          } else {
            this.changePriceList(this.currentRow.priceList);
          }
        } else {
          chargeStrategy.detail({ stationId }).then(res => {
            if (res && typeof res === 'object') {
              this.ruleForm.priceType = res.priceType;
              if (this.ruleForm.priceType === '0') {
                this.priceData = {
                  feeList: res.feeList,
                  priceList: res.priceList,
                };
              } else {
                this.changePriceList(res.priceList);
              }
            } else {
              this.resetPriceData();
            }
          });
        }
      },
      /**
       * 提交
       */
      submitForm(callback) {
        console.log('this.ruleForm+++', this.ruleForm);
        if (this.ruleForm.priceType === '0') {
          this.PeaksValleysFun(callback);
        } else {
          this.RateFun(callback);
        }
      },
      /**
       * 尖峰平谷提交
       */
      PeaksValleysFun(callback) {
        console.log('this.$refs.ykcPrice.saveVerifyOMP()+++', this.$refs.ykcPrice.saveVerifyOMP());
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        Promise.all([promisify(this.$refs.ruleForm), this.$refs.ykcPrice.saveVerifyOMP()]).then(
          res => {
            if (res.every(valid => !!valid)) {
              if (this.operateType === 'single') {
                const peaksData = this.$refs.ykcPrice.saveVerifyOMP();
                const parameter = {
                  periodType: '1',
                  stationId: this.currentRow.stationId,
                  ...this.ruleForm,
                  ...peaksData,
                };
                console.log('parameter+++', parameter);
                chargeStrategy.edit(parameter).then(response => {
                  console.log('chargeStrategy single edit', response);
                  callback();
                });
              } else if (this.operateType === 'multiple') {
                const peaksData = this.$refs.ykcPrice.saveVerifyOMP();
                const parameter = {
                  periodType: '1',
                  stationIds: this.selectedRows.map(({ stationId }) => stationId),
                  ...this.ruleForm,
                  ...peaksData,
                };
                chargeStrategy.batchEdit(parameter).then(response => {
                  console.log('chargeStrategy multiple edit', response);
                  callback();
                });
              }
            }
          }
        );
      },
      /**
       * 48费率提交
       */
      RateFun(callback) {
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });

        Promise.all([promisify(this.$refs.ruleForm), this.$refs.RangePrice.validate()]).then(
          res => {
            console.log('res+++', res);
            if (res.every(valid => !!valid)) {
              console.log('this.ruleForm+++this.restConfig', this.ruleForm, this.restConfig);
              if (this.operateType === 'single') {
                const parameter = {
                  ...this.ruleForm,
                  stationId: this.currentRow.stationId,
                  periodType: '1',
                };
                parameter.priceList = parameter.priceList.map(
                  ({ start, end, charge, service }) => ({
                    priceStartPeriod: start,
                    priceEndPeriod: end,
                    powerRate: charge,
                    serviceRate: service,
                    defaultFlag: '0',
                  })
                );
                if (this.$refs.RangePrice.availableRanges.length > 0) {
                  parameter.priceList.push({
                    priceStartPeriod: '0',
                    priceEndPeriod: '0',
                    powerRate: this.restConfig.charge,
                    serviceRate: this.restConfig.service,
                    defaultFlag: '1',
                  });
                }
                if (parameter.configType !== '1') {
                  parameter.configStationId = '';
                }
                chargeStrategy.edit(parameter).then(response => {
                  console.log('chargeStrategy single edit', response);
                  callback();
                });
              } else if (this.operateType === 'multiple') {
                const parameter = {
                  ...this.ruleForm,
                  stationIds: this.selectedRows.map(({ stationId }) => stationId),
                  periodType: '1',
                };
                parameter.priceList = parameter.priceList.map(
                  ({ start, end, charge, service }) => ({
                    priceStartPeriod: start,
                    priceEndPeriod: end,
                    powerRate: charge,
                    serviceRate: service,
                    defaultFlag: '0',
                  })
                );
                if (this.$refs.RangePrice.availableRanges.length > 0) {
                  parameter.priceList.push({
                    priceStartPeriod: '0',
                    priceEndPeriod: '0',
                    powerRate: this.restConfig.charge,
                    serviceRate: this.restConfig.service,
                    defaultFlag: '1',
                  });
                }
                if (parameter.configType !== '1') {
                  parameter.configStationId = '';
                }
                chargeStrategy.batchEdit(parameter).then(response => {
                  console.log('chargeStrategy multiple edit', response);
                  callback();
                });
              }
            }
          }
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
