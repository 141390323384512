<template>
  <div class="ykc-price">
    <ykc-form ref="fromRules">
      <ykc-form-item
        :required="true"
        :label="'价格类别'"
        :class="typeList.length == 1 ? 'box-bottom' : ''">
        <div class="configCategory" v-for="(item, index) in typeList" :key="index">
          <!-- <ykc-icon name="reduce" :class="{ disabled: false }"></ykc-icon> -->
          <ykc-input
            class="configCategory-input"
            v-model="item.name"
            :maxlength="5"
            :disabled="true"
            :placeholder="'请输入类别名称'"
            :class="{ error: item.errorName }"
            @blur="verifyTypes(1, index, item)">
            <template slot="prepend">类别名称</template>
          </ykc-input>
          <ykc-input
            class="box-width box-width-right"
            v-model="item.price"
            :maxlength="6"
            :disabled="priceDisabled"
            :placeholder="'请输入电费单价'"
            :class="{ error: item.errorPrice }"
            @blur="verifyTypes(2, index, item)">
            <template slot="prepend">电费</template>
            <template slot="append">元/度</template>
          </ykc-input>
          <ykc-input
            class="box-width"
            v-model="item.servicePrice"
            :maxlength="6"
            :disabled="priceDisabled"
            :placeholder="'请输入服务费单价'"
            :class="{ error: item.errorServicePrice }"
            @blur="verifyTypes(3, index, item)">
            <template slot="prepend">服务费</template>
            <template slot="append">元/度</template>
          </ykc-input>
          <div
            class="rules-error weight-syl el-form-item__error"
            v-if="item.errorName || item.errorPrice || item.errorServicePrice">
            {{ item.errorName || item.errorPrice || item.errorServicePrice }}
          </div>
        </div>
      </ykc-form-item>
      <ykc-form-item :required="true" :label="'价格设置'" v-show="typeList.length > 1">
        <div class="configCategory" v-for="(item, index) in timeList" :key="index">
          <ykc-icon name="reduce" @click="clickTimeDel($event, index, item)"></ykc-icon>
          <ykc-dropdown
            class="box-width"
            v-model="item.startValue"
            :data="startTimeList"
            :configSet="configSet"
            :disabled="priceDisabled"
            :placeholder="'请选择开始时间'"
            :class="{ error: item.errorStart }"
            @focus="selectFocusTime(1, index, item)"
            @change="selectChange($event, 1)"></ykc-dropdown>
          <div class="time-line color-grey">至</div>
          <ykc-dropdown
            class="box-width"
            v-model="item.endValue"
            :data="endTimeList"
            :configSet="configSet"
            :disabled="priceDisabled"
            :placeholder="'请选择结束时间'"
            :class="{ error: item.errorEnd }"
            @focus="selectFocusTime(2, index, item)"
            @change="selectChange($event, 2)"></ykc-dropdown>
          <ykc-dropdown
            class="box-width box-width-left"
            v-model="item.typeName"
            :data="typeList"
            :configSet="configSet"
            :disabled="priceDisabled"
            :placeholder="'请选择价格类别'"
            :class="{ error: item.errorName }"
            @change="selectChange($event, 3)"></ykc-dropdown>
          <div
            v-if="(item.errorStart || item.errorEnd || item.errorName) && typeList.length > 1"
            class="rules-error weight-syl el-form-item__error">
            {{ item.errorStart || item.errorEnd || item.errorName }}
          </div>
        </div>
        <div class="configCategory" v-show="isAddTime !== 2">
          <ykc-icon name="reduce"></ykc-icon>
          <ykc-input
            class="time-width"
            :disabled="true"
            :placeholder="'剩余时段价格统一选择'"></ykc-input>
          <ykc-dropdown
            class="box-width box-width-left"
            :data="typeList"
            v-model="otherTime"
            :configSet="configSet"
            :disabled="priceDisabled"
            @change="selectChange($event, 4)"></ykc-dropdown>
        </div>
        <div
          class="pointer bnt-width"
          v-show="isAddTime !== 2 && !priceDisabled"
          @click="clickAddTime">
          <ykc-icon name="add" :class="{ disabled: false }"></ykc-icon>
          <div class="bnt-text">添加时间区间</div>
        </div>
      </ykc-form-item>
    </ykc-form>
  </div>
</template>

<script>
  import { toFixed } from '@/utils';

  export default {
    name: 'ykc-price',
    props: {
      priceData: null, // 价格数据
      priceDisabled: {
        type: Boolean,
        default: false, // 是否禁用，默认不禁用
      },
    },
    data() {
      return {
        otherTime: '', // 剩余时间类别值
        isAddTime: 1, // 是否可以增加时间区间 0置灰  1可点击 2隐藏
        IS_1TO4: /^\d{0,1}(\.\d{0,4})?$/, // 小数数字类  默认:小数点前1位、后4位
        configSet: { label: 'label', value: 'value' },
        typeNames: ['尖', '峰', '平', '谷'],
        timeList: [
          {
            startValue: '', // 开始时间值
            endValue: '', // 结束时间值
            typeName: '', // 选择的价格类型名称
            errorStart: '', // 校验提示-开始时间
            errorEnd: '', // 校验提示-结束时间
            errorName: '', // 校验提示-价格类型
          },
        ],
        typeList: [
          {
            name: '尖', // 价格类别名称
            price: '', // 电价
            servicePrice: '', // 服务费
            errorName: '', // 校验提示
            errorPrice: '', // 校验提示
            errorServicePrice: '', // 校验提示
            label: '尖', // 选择时间区间时，显示的价格类别名称
            value: '尖', // 选择时间区间时，显示的价格类别值
            isDel: false, // 是否可以删除
          },
          {
            name: '峰', // 价格类别名称
            price: '', // 电价
            servicePrice: '', // 服务费
            errorName: '', // 校验提示
            errorPrice: '', // 校验提示
            errorServicePrice: '', // 校验提示
            label: '峰', // 选择时间区间时，显示的价格类别名称
            value: '峰', // 选择时间区间时，显示的价格类别值
          },
          {
            name: '平', // 价格类别名称
            price: '', // 电价
            servicePrice: '', // 服务费
            errorName: '', // 校验提示
            errorPrice: '', // 校验提示
            errorServicePrice: '', // 校验提示
            label: '平', // 选择时间区间时，显示的价格类别名称
            value: '平', // 选择时间区间时，显示的价格类别值
          },
          {
            name: '谷', // 价格类别名称
            price: '', // 电价
            servicePrice: '', // 服务费
            errorName: '', // 校验提示
            errorPrice: '', // 校验提示
            errorServicePrice: '', // 校验提示
            label: '谷', // 选择时间区间时，显示的价格类别名称
            value: '谷', // 选择时间区间时，显示的价格类别值
          },
        ],
        endTimeList: [], // 结束时间集合
        startTimeList: [], // 开始时间集合
      };
    },
    watch: {
      priceData: {
        handler() {
          this.setData();
        },
        deep: true,
        immediate: true,
      },
      priceDisabled(val) {
        this.priceDisabled = val;
      },
    },
    mounted() {
      this.startTimeList = this.timeInit(1);
      this.endTimeList = this.timeInit(0);
      console.log('dddd', this.priceDisabled);

      // 编辑时回填数据
      // this.setData()
    },

    methods: {
      init() {
        this.timeList = [
          {
            startValue: '', // 开始时间值
            endValue: '', // 结束时间值
            typeName: '', // 选择的价格类型名称
            errorStart: '', // 校验提示-开始时间
            errorEnd: '', // 校验提示-结束时间
            errorName: '', // 校验提示-价格类型
          },
        ];
        this.isAddTime = 1;
      },
      /**
       * 回填数据
       */
      setData() {
        if (this.priceData) {
          this.init();
          const type = this.priceData.feeList || [];
          const time = this.priceData.priceList || [];
          if (type.length === 1) {
            // 只有一个价格类别
            this.setTypeList(type, 0);
          } else {
            // 多个价格类别
            // 回填价格类别
            for (let i = 0; i < type.length; i++) {
              this.setTypeList(type, i);
            }
            // 回填时间区间
            if (time.length === 1) {
              // 只有一个时间区间
              this.setTimeList(time, 0, 0);
              this.isAddTime = 2;
            } else {
              this.isAddTime = 1;
              let j = 0;
              for (let i = 0; i < time.length; i++) {
                if (time[i].defaultFlag === '0') {
                  // 非剩余价格
                  if (j !== 0) {
                    this.addTimeList();
                  }
                  this.setTimeList(time, i, j);
                  j++;
                } else {
                  // 剩余价格
                  this.otherTime = this.typeList[time[i].feeType - 1].name;
                  this.isAddTime = 1;
                }
              }
            }
          }
        }
      },
      /**
       * 给价格类别项赋值
       */
      setTypeList(item, index) {
        // this.typeList[index].name = item[index].priceTypeName
        Object.assign(this.typeList[index], {
          name: this.typeNames[index],
          price: item[index].powerRate,
          value: this.typeNames[index],
          servicePrice: item[index].serviceRate,
          label: `${this.typeList[index].name}：电费【${item[index].powerRate}元】服务费【${item[index].serviceRate}元】`,
          // label: `${this.typeList[index].name}：电费【${this.typeList[index].price}元】服务费【${this.typeList[index].servicePrice}元】`,
        });
      },
      /**
       * 增加时间区间
       */
      addTimeList() {
        const obj = {
          startValue: '', // 开始时间值
          endValue: '', // 结束时间值
          typeName: '', // 选择的价格类型名称
          errorStart: '', // 校验提示-开始时间
          errorEnd: '', // 校验提示-结束时间
          errorName: '', // 校验提示-价格类型
        };
        this.timeList.push(obj);
      },
      /**
       * 给时间区间项赋值
       */
      setTimeList(item, index, key) {
        this.timeList[key].startValue = Number(item[index].priceStartPeriod);
        this.timeList[key].endValue = Number(item[index].priceEndPeriod);
        this.timeList[key].typeName = this.typeList[item[index].feeType - 1].name;
      },
      /**
       * 初始化时间格式 48个时段
       */
      timeInit(type) {
        const timeBlock = [];
        for (let i = 0; i < 49; i++) {
          let a = 1;
          let b = 0;
          let label = '';
          let value = 0;
          let temp = {};
          a = Math.floor(i / 2);
          a = a > 9 ? a.toString() : `0${a}`;
          b = (i % 2) * 30;
          b = b === 30 ? b.toString() : `${b}0`;

          label = `${a}:${b}`;
          value = i;

          if (type === 0) {
            temp = {
              label,
              value: value + 1,
              disabled: i === 0,
            };
          } else {
            temp = {
              label,
              value: value + 1,
              disabled: i === 48,
            };
          }
          timeBlock[i.toString()] = temp;
        }
        return timeBlock;
      },
      /**
       * 校验价格类别
       */
      verifyType(name, verify, msgNull, msgVerify) {
        if (!name) {
          return msgNull;
        }
        if (!verify.test(name)) {
          return msgVerify;
        }
        return '';
      },
      /**
       * 校验价格类别是否有相同
       */
      verifyTypeEqual(name, index) {
        let flag = true;
        for (let i = 0; i < this.typeList.length; i++) {
          const item = this.typeList[i];
          if (index !== i && item.name === name) {
            flag = false;
            break;
          }
        }
        return flag;
      },
      /**
       * 校验价格类型
       */
      verifyTypes(type, index, item) {
        if (item) {
          // 失去焦点时校验
          if (type === 2) {
            // 校验电价
            item.errorPrice = this.verifyType(
              item.price,
              this.IS_1TO4,
              '请输入电费',
              '请输入正确的电费（范围：0.0000~9.9999）'
            );
            if (item.price && !item.errorPrice) {
              item.price = toFixed(item.price, 4);
            }
          } else if (type === 3) {
            // 校验服务费
            item.errorServicePrice = this.verifyType(
              item.servicePrice,
              this.IS_1TO4,
              '请输入服务费',
              '请输入正确的服务费（范围：0.0000~9.9999）'
            );
            if (item.servicePrice && !item.errorServicePrice) {
              item.servicePrice = toFixed(item.servicePrice, 4);
            }
          }
          if (!item.errorName) {
            item.value = item.name;
          }
          item.label = `${item.name}：电费【${item.price}元】服务费【${item.servicePrice}元】`;
          this.$set(this.typeList, index, item);
        } else {
          // 全部校验
          for (let i = this.typeList.length - 1; i >= 0; i--) {
            const items = this.typeList[i];
            items.errorPrice = this.verifyType(
              items.price,
              this.IS_1TO4,
              '请输入电费',
              '请输入正确的电费（范围：0.0000~9.9999）'
            );
            items.errorServicePrice = this.verifyType(
              items.servicePrice,
              this.IS_1TO4,
              '请输入服务费',
              '请输入正确的服务费（范围：0.0000~9.9999）'
            );
          }
        }
      },
      /**
       * 判断价格类型是否校验通过
       */
      isType() {
        let flag = true;
        for (let i = this.typeList.length - 1; i >= 0; i--) {
          const item = this.typeList[i];
          if (
            !item.name ||
            !item.price ||
            !item.servicePrice ||
            item.errorName ||
            item.errorPrice ||
            item.errorServicePrice
          ) {
            flag = false;
            break;
          }
        }
        return flag;
      },
      /**
       * 开始下拉选择时间区间
       */
      selectFocusTime(type, index, item) {
        if (type === 1) {
          // 选择开始时间
          for (let i = 0; i < 48; i++) {
            this.startTimeList[i].disabled = false;
          }
          if (item.endValue) {
            // 当前区间有结束时间时，结束时间之后的开始时间选项置灰
            for (let i = item.endValue - 1; i < 48; i++) {
              this.startTimeList[i].disabled = true;
            }
          }
          for (let i = 0; i < this.timeList.length; i++) {
            const tmp = this.timeList[i];
            if (i !== index) {
              // 非当前时间区间
              if (item.endValue && item.endValue > tmp.endValue) {
                // 如有当前结束时间且当前结束时间>已选结束时间，已选结束时间之前置灰
                for (let j = 0; j < tmp.endValue - 1; j++) {
                  this.startTimeList[j].disabled = true;
                }
              } else {
                // 将已选时间区间的时间选项置灰
                for (let j = tmp.startValue - 1; j < tmp.endValue - 1; j++) {
                  this.startTimeList[j].disabled = true;
                }
              }
            }
          }
        } else {
          // 选择结束时间
          for (let i = 1; i < 49; i++) {
            this.endTimeList[i].disabled = false;
          }
          if (item.startValue) {
            // 当前区间有开始时间时，开始时间之前的开始时间选项置灰
            for (let i = 1; i < item.startValue; i++) {
              this.endTimeList[i].disabled = true;
            }
          }
          for (let i = 0; i < this.timeList.length; i++) {
            const tmp = this.timeList[i];
            if (i !== index) {
              // 非当前时间区间
              if (item.startValue && item.startValue < tmp.startValue) {
                // 如果当前开始时间且当前开始时间<已选当前时间，已选当前时间之后的选项置灰
                for (let j = tmp.startValue; j < 49; j++) {
                  this.endTimeList[j].disabled = true;
                }
              } else {
                // 将已选时间区间的时间选项置灰
                for (let j = tmp.startValue; j < tmp.endValue; j++) {
                  this.endTimeList[j].disabled = true;
                }
              }
            }
          }
        }
      },
      /**
       * 选项时间区间和类别时事件-校验
       */
      selectChange(evt, type) {
        if (type < 4) {
          // 非剩余时段进行校验
          this.verifyTime();
        }
      },
      /**
       * 校验时间区间
       */
      verifyTime(isMsg) {
        let flag = true;
        for (let i = 0; i < this.timeList.length; i++) {
          const item = this.timeList[i];
          if (item.startValue) {
            item.errorStart = '';
          } else if (!item.startValue) {
            flag = false;
            if (isMsg) {
              item.errorStart = '请选择开始时间';
            } else {
              break;
            }
          }
          if (item.endValue) {
            item.errorEnd = '';
          } else if (!item.endValue) {
            flag = false;
            if (isMsg) {
              item.errorEnd = '请选择结束时间';
            } else {
              break;
            }
          }
          if (item.typeName) {
            item.errorName = '';
          } else if (!item.typeName) {
            flag = false;
            if (isMsg) {
              item.errorName = '请选择价格类型';
            } else {
              break;
            }
          }
        }

        const arr = [];
        for (let i = 0; i < this.timeList.length; i++) {
          const item = this.timeList[i];
          for (let j = item.startValue; j < item.endValue; j++) {
            arr.push(j);
          }
        }
        if (arr.length === 48) {
          this.isAddTime = 2;
        } else {
          this.isAddTime = 0;
          if (!this.otherTime) {
            this.otherTime = this.typeList[0].name;
          }
        }

        if (this.isAddTime !== 2) {
          this.isAddTime = flag ? 1 : 0;
        }
      },
      /**
       * 判断时间区间是否校验通过
       */
      isTime() {
        let flag = true;
        if (this.typeList.length > 1) {
          for (let i = this.timeList.length - 1; i >= 0; i--) {
            const item = this.timeList[i];
            if (
              !item.startValue ||
              !item.endValue ||
              !item.typeName ||
              item.errorStart ||
              item.errorEnd ||
              item.errorName
            ) {
              flag = false;
              break;
            }
          }
        }

        return flag;
      },
      /**
       * 添加时间区间
       */
      clickAddTime() {
        this.verifyTime(true);
        if (this.isAddTime === 1) {
          this.addTimeList();
          this.isAddTime = 0;
        }
      },
      /**
       * 删除时间区间
       */
      clickTimeDel(evt, index, item) {
        if (this.priceDisabled) {
          // 禁用不能操作
          return;
        }
        if (index === 0 && item.startValue === 1 && item.endValue === 49) {
          return;
        }
        if (this.timeList.length > 1) {
          // 删除价格类型
          this.timeList.splice(index, 1);
        }
        if (this.isAddTime !== 1) {
          // 时间区间  显示增加按钮
          this.isAddTime = 1;
        }
        // 判断未选中的价格类别可以删除
        this._.find(this.typeList, { name: item.typeName }).isDel = true;
      },
      /**
       *
       */
      saveVerifyOMP() {
        this.verifyTypes();
        this.verifyTime(true);
        let obj = null;
        if (this.isType() && this.isTime()) {
          obj = {};
          obj.feeList = []; // 价格组集合

          this.typeList.forEach((item, index) => {
            obj.feeList.push({
              feeType: index + 1, // 下标
              priceTypeName: item.name, // 类别名称
              powerRate: Number(item.price), // 电费
              serviceRate: Number(item.servicePrice), // 服务费
              stationFeeId: '',
            });
          });

          obj.priceList = []; // 充电费用集合

          if (this.typeList.length > 1) {
            this.timeList.forEach(item => {
              this.typeList.forEach((item1, index1) => {
                if (item.typeName === item1.name) {
                  obj.priceList.push({
                    defaultFlag: '0', // 是否剩余 0非剩余  1剩余
                    priceStartPeriod: item.startValue, // 开始时间
                    priceEndPeriod: item.endValue, // 结束时间
                    feeType: index1 + 1, // 类别下标
                    powerRate: Number(item1.price), // 电费
                    serviceRate: Number(item1.servicePrice), // 服务费
                    stationPriceId: '',
                  });
                }
              });
            });

            if (this.isAddTime !== 2) {
              this.typeList.forEach((item, index) => {
                if (this.otherTime === item.name) {
                  obj.priceList.push({
                    defaultFlag: '1', // 是否剩余 0非剩余  1剩余
                    priceStartPeriod: 0, // 开始时间
                    priceEndPeriod: 0, // 结束时间
                    feeType: index + 1, // 类别下标
                    powerRate: Number(item.price), // 电费
                    serviceRate: Number(item.servicePrice), // 服务费
                    stationPriceId: '',
                  });
                }
              });
            }
          } else {
            obj.priceList.push({
              defaultFlag: '0', // 是否剩余 0非剩余  1剩余
              priceStartPeriod: 1, // 开始时间
              priceEndPeriod: 49, // 结束时间
              feeType: 1, // 类别下标
              powerRate: Number(this.typeList[0].price), // 电费
              serviceRate: Number(this.typeList[0].servicePrice), // 服务费
              stationPriceId: '',
            });
          }
        }
        console.log(obj);
        return obj;
      },
    },
  };
</script>

<style lang="scss">
  .ykc-price {
    .configCategory {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .ykc-icon-reduce {
        height: 16px;
        margin-top: 7px;
        margin-right: 8px;
        cursor: pointer;
        text-align: right;
        color: var(--theme-color-primary);

        &.disabled {
          color: #d7d7d7;
          cursor: not-allowed;
        }
      }

      .configCategory-input {
        width: 120px;
      }

      .box-width-right {
        margin: 0 8px;
      }

      .box-width-left {
        margin-left: 8px;
      }

      .box-width {
        flex: 1;
      }
    }

    .pointer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      .ykc-icon-add {
        height: 16px;
        margin-top: 1px;
        margin-right: 8px;
        cursor: pointer;
        text-align: right;
        font-size: 15px;
        color: var(--theme-color-primary);

        &.disabled {
          color: #d7d7d7;
          cursor: not-allowed;
        }
      }

      .bnt-text {
        cursor: pointer;
        font-size: 14px;
        color: var(--theme-color-primary);
      }
    }

    .time-line {
      margin: 0 6.5px;
    }

    .time-width {
      width: 378px;
    }
  }
</style>
