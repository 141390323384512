<template>
  <div class="range-price">
    <DynamicCompose :createItem="createItem" :value="value" @input="handleInput" :max="48">
      <template #default="{ data, id, handleDelete, deleteDisabled, index }">
        <ykc-form class="range-price-form" :model="data" :rules="rules">
          <ykc-icon
            name="reduce"
            :class="{ disabled: deleteDisabled }"
            @click="deleteRow({ deleteDisabled, handleDelete, id })"></ykc-icon>
          <ykc-form-item prop="start">
            <ykc-dropdown
              v-model="data.start"
              :clearable="true"
              :data="data.startData"
              style="width: 110px"
              @change="handleRangeChange"
              placeholder="开始时间"></ykc-dropdown>
          </ykc-form-item>
          <div>~</div>
          <ykc-form-item prop="end">
            <ykc-dropdown
              v-model="data.end"
              :clearable="true"
              :data="data.endData"
              style="width: 110px"
              @change="handleRangeChange"
              placeholder="结束时间"></ykc-dropdown>
          </ykc-form-item>
          <ykc-form-item prop="charge" style="margin-left: 8px">
            <ykc-input v-model="data.charge" type="number" placeholder="请输入电费单价">
              <template #append>元</template>
            </ykc-input>
          </ykc-form-item>
          <ykc-form-item prop="service" style="margin-left: 8px">
            <ykc-input v-model="data.service" type="number" placeholder="请输入服务费单价">
              <template #append>元</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form>
        <template v-if="index + 1 === value.length">
          <ykc-form
            ref="RestForm"
            :model="restConfig"
            :rules="restRules"
            class="rest-range-form"
            v-if="availableRanges.length > 0">
            <ykc-form-item class="label">
              <ykc-input value="剩余时间段统一选择" readonly></ykc-input>
            </ykc-form-item>
            <ykc-form-item style="margin-left: 8px" prop="charge">
              <ykc-input v-model="restConfig.charge" type="number" placeholder="请输入电费单价">
                <template #append>元</template>
              </ykc-input>
            </ykc-form-item>
            <ykc-form-item style="margin-left: 8px" prop="service">
              <ykc-input v-model="restConfig.service" type="number" placeholder="请输入服务费单价">
                <template #append>元</template>
              </ykc-input>
            </ykc-form-item>
          </ykc-form>
        </template>
      </template>
      <template #add="{ handleAdd, reachMax }">
        <div class="range-price-add" @click="addItem({ handleAdd, reachMax })">
          <ykc-icon name="add"></ykc-icon>
          <span>添加时间区间</span>
        </div>
      </template>
    </DynamicCompose>
  </div>
</template>

<script>
  import DynamicCompose from './dynamic-compose.vue';

  export default {
    components: { DynamicCompose },
    props: {
      value: {
        type: Array,
        required: true,
      },
      restConfig: {
        type: Object,
        required: true,
      },
    },
    watch: {
      value: {
        handler: 'handleRangeChange',
      },
    },
    data() {
      return {
        totalRangeData: Array.from({ length: 49 }).map((_, index) => {
          const id = `${index + 1}`;
          const hour = Math.floor(index / 2);
          const minute = (index % 2) * 30;
          const name = `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`;
          return {
            id,
            hour,
            minute,
            name,
          };
        }),
      };
    },
    computed: {
      availableRanges() {
        const usedItems = [...this.value]
          // 过滤出都有值的数据
          .filter(item => item.start && item.end)
          .sort((a, b) => Number(a.start) - Number(b.start));
        if (usedItems.length === 0) return [{ start: 1, end: 49 }];
        const ranges = [];
        usedItems.forEach(({ start, end }, index) => {
          start = Number(start);
          end = Number(end);
          const prev = usedItems[index - 1];
          const next = usedItems[index + 1];
          // 第一个
          if (!prev) {
            // 前面还有区间
            if (start > 1) {
              ranges.push({ start: 1, end: start });
            }
          } else {
            // 不是第一个
            // 如果区间不重合
            // eslint-disable-next-line
            if (start !== Number(prev.end)) {
              ranges.push({
                start: Number(prev.end),
                end: start,
              });
            }
          }
          // 最后一个
          if (!next) {
            // 后面还有区间
            if (end < 49) {
              ranges.push({
                start: end,
                end: 49,
              });
            }
          }
        });
        return ranges;
      },
      restRules() {
        return {
          charge: [
            {
              required: true,
              message: '请输入电费单价',
              trigger: 'blur',
            },
            {
              validator: (rule, val, callback) => {
                const value = Number(val);
                if (Number.isNaN(value)) {
                  callback(new Error('请输入数字'));
                }
                if (val.replace(/.*\.(.*)$/, '$1').length > 4) {
                  callback(new Error('最多四位小数'));
                }
                if (value < 0 || value > 9.9999) {
                  callback(new Error('请输入0-9.9999的电费单价'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
          service: [
            {
              required: true,
              message: '请输入服务费单价',
              trigger: 'blur',
            },
            {
              validator: (rule, val, callback) => {
                const value = Number(val);
                if (Number.isNaN(value)) {
                  callback(new Error('请输入数字'));
                }
                if (val.replace(/.*\.(.*)$/, '$1').length > 4) {
                  callback(new Error('最多四位小数'));
                }
                if (value < 0 || value > 9.9999) {
                  callback(new Error('请输入0-9.9999的服务费单价'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
        };
      },
      rules() {
        return {
          start: [
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback(new Error('请选择开始时间'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
          end: [
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback(new Error('请选择结束时间'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
          charge: [
            {
              required: true,
              message: '请输入电费单价',
              trigger: 'blur',
            },
            {
              validator: (rule, val, callback) => {
                const value = Number(val);
                if (Number.isNaN(value)) {
                  callback(new Error('请输入数字'));
                }
                if (val.replace(/.*\.(.*)$/, '$1').length > 4) {
                  callback(new Error('最多四位小数'));
                }
                if (value < 0 || value > 9.9999) {
                  callback(new Error('请输入0-9.9999的电费单价'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
          service: [
            {
              required: true,
              message: '请输入服务费单价',
              trigger: 'blur',
            },
            {
              validator: (rule, val, callback) => {
                const value = Number(val);
                if (Number.isNaN(value)) {
                  callback(new Error('请输入数字'));
                }
                if (val.replace(/.*\.(.*)$/, '$1').length > 4) {
                  callback(new Error('最多四位小数'));
                }
                if (value < 0 || value > 9.9999) {
                  callback(new Error('请输入0-9.9999的服务费单价'));
                }
                callback();
              },
              trigger: 'blur',
            },
          ],
        };
      },
    },
    methods: {
      handleInput(val) {
        this.$emit('input', val);
      },
      deleteRow({ deleteDisabled, handleDelete, id }) {
        if (deleteDisabled) return;
        handleDelete(id);
        // 删除后,重新计算可用的下拉选项
        this.handleRangeChange();
      },
      createItem() {
        const item = {
          start: '',
          end: '',
          charge: '',
          service: '',
          startData: [],
          endData: [],
        };
        this.calculateAvailableRangeData(item);
        return item;
      },
      // eslint-disable-next-line
      calculateAvailableRangeData(current) {
        const availableRanges = [...this.availableRanges];
        console.log('availableRanges', JSON.stringify(availableRanges));
        if (!current.start) {
          if (!current.end) {
            // 开始时间和结束时间都没有
            console.log('开始时间和结束时间都没有');
            current.startData = this.totalRangeData.filter(({ id }) =>
              availableRanges.some(({ start, end }) => Number(id) >= start && Number(id) < end)
            );
            current.endData = this.totalRangeData.filter(({ id }) =>
              availableRanges.some(({ start, end }) => Number(id) <= end && Number(id) > start)
            );
          } else {
            // 有结束时间，没有开始时间
            console.log('没有开始时间，但有结束时间');
            const exist = availableRanges.find(
              // 正常来讲，end >= start + 1 是一定成立的
              // 找到落在哪个区间内
              ({ start, end }) => Number(current.end) <= end && Number(current.end) > start
            );
            if (exist) {
              console.log('exist', exist);
              current.startData = this.totalRangeData.filter(
                ({ id }) => Number(id) < Number(current.end) && Number(id) >= Number(exist.start)
              );
            } else {
              console.warn('当前节点不在区间内', current.end, JSON.stringify(availableRanges));
              current.end = '';
              this.calculateAvailableRangeData(current);
            }
          }
        } else {
          // eslint-disable-next-line
          if (!current.end) {
            // 有开始时间，没有结束时间
            console.log('有开始时间，没有结束时间');
            const exist = availableRanges.find(
              // 正常来讲，end >= start + 1 是一定成立的
              // 找到落在哪个区间内
              ({ start, end }) => start <= Number(current.start) && end > Number(current.start)
            );
            if (exist) {
              console.log('exist', exist);
              current.endData = this.totalRangeData.filter(
                ({ id }) => Number(id) > Number(current.start) && Number(id) <= Number(exist.end)
              );
            } else {
              console.warn('当前节点不在区间内', current.start, JSON.stringify(availableRanges));
              current.start = '';
              this.calculateAvailableRangeData(current);
            }
          } else {
            // 有开始时间，也有结束时间
            const usedRanges = [...this.value]
              // 过滤出都有值的数据
              .filter(item => item.start && item.end)
              .sort((a, b) => Number(a.start) - Number(b.start));
            usedRanges.forEach((item, index) => {
              const prev = usedRanges[index - 1];
              const next = usedRanges[index + 1];
              // 第一个
              if (!prev) {
                // 第一个,start一定是从1开始
                item.startData = this.totalRangeData.filter(
                  ({ id }) => Number(id) < Number(item.end) && Number(id) >= 1
                );
                if (!next) {
                  // 最后一个
                  item.endData = this.totalRangeData.filter(
                    ({ id }) => Number(id) > Number(item.start) && Number(id) <= 49
                  );
                } else {
                  // 不是最后一个
                  item.endData = this.totalRangeData.filter(
                    ({ id }) => Number(id) > Number(item.start) && Number(id) <= Number(next.start)
                  );
                }
              } else {
                item.startData = this.totalRangeData.filter(
                  ({ id }) => Number(id) >= Number(prev.end) && Number(id) < Number(item.end)
                );
                // eslint-disable-next-line
                if (!next) {
                  // 不是第一个,且是最后一个
                  item.endData = this.totalRangeData.filter(
                    ({ id }) => Number(id) > Number(item.start) && Number(id) <= 49
                  );
                } else {
                  // 不是第一个,且不是最后一个
                  item.endData = this.totalRangeData.filter(
                    ({ id }) => Number(id) > Number(item.start) && Number(id) <= Number(next.start)
                  );
                }
              }
            });
          }
        }
        this.$forceUpdate();
      },
      handleRangeChange() {
        this.$nextTick(() => {
          this.value.forEach(this.calculateAvailableRangeData);
        });
      },
      validate(validateRest = true) {
        const ykcFormVms = [...this.$el.querySelectorAll('.ykc-form.range-price-form')].map(
          // eslint-disable-next-line
          node => node.__vue__
        );
        if (validateRest === true) {
          const restForm = this.$el.querySelector('.ykc-form.rest-range-form');
          if (restForm) {
            // eslint-disable-next-line
            ykcFormVms.push(restForm.__vue__);
          }
        }
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        return new Promise(resolve => {
          Promise.all(ykcFormVms.map(promisify)).then(res => {
            resolve(res.every(valid => !!valid));
          });
        });
      },
      addItem({ handleAdd, reachMax }) {
        if (reachMax) {
          // 这里可以给出提示
          return;
        }
        this.validate(false).then(valid => {
          if (valid) {
            handleAdd();
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .range-price {
    color: inherit;
    &-form {
      display: flex;
      .ykc-icon-reduce {
        width: 30px;
        text-align: right;
        height: 16px;
        margin-top: 7px;
        margin-right: 8px;
        color: var(--theme-color-primary);
        cursor: pointer;
        &.disabled {
          color: #d7d7d7;
          cursor: not-allowed;
        }
      }
    }
    // 处理4.0样式干扰
    & .el-form-item.is-success {
      .ykc-input.el-input {
        .el-input-group__prepend {
          border: 1px solid #d7d7d7 !important;
          border-right: 0 !important;
        }
        .el-input__inner {
          border: 1px solid #d7d7d7 !important;
        }
      }
      .ykc-dropdown-box {
        .el-input-group__prepend {
          border: 1px solid #d7d7d7 !important;
          border-right: 0 !important;
        }
        .el-input__inner {
          border: 1px solid #d7d7d7 !important;
        }
      }
    }
    &-add {
      margin-left: -4px;
      margin-top: 4px;
      cursor: pointer;
      font-size: 12px;
      // font-family: PingFangSC-Medium, PingFang SC;
      // font-weight: 500;
      // line-height: 17px;
      color: var(--theme-color-primary);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      .ykc-icon-add {
        width: 30px;
        text-align: right;
        margin-right: 8px;
        color: var(--theme-color-primary);
      }
    }
    .rest-range-form.el-form.ykc-form {
      display: flex;
      padding-left: 36px;
      .ykc-form-item.label {
        width: 254px;
      }
    }
  }
</style>
